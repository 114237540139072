<template>
	<div class="visit-content" @click="toVisit">
		<div class="visit-store-info">
			<div class="store-basic">
				<span>{{ visitDetail.customerName }}</span>
			</div>
			<div class="store-data">
				<span class="project-name">{{ visitDetail.projectName }} Project</span>
				<span class="create-time">{{ visitDetail.createTime }}</span>
			</div>
			<div class="store-status">
				<div style="display: flex; align-items: center; width: 145px;">
						<div v-for="(statusItem, statusIndex) in statusList" :key="statusIndex" class="status-item">
						<!-- <span v-if="visitDetail[statusItem.value] > 0" class="status-checked"><van-icon name="success" /></span>
						<span v-else class="status-checke"></span> -->
						<div v-if="statusItem.value === 'materielCount'" class="tb-img">
							<img v-if="visitDetail[statusItem.value] > 0" src="@/assets/images/icon_ars_material_ok.png" alt="">
							<img v-else src="@/assets/images/icon_ars_material.png" alt="">
						</div>
						<div v-else-if="statusItem.value === 'mainMaterielCount'" class="tb-img">
							<img v-if="visitDetail[statusItem.value] > 0" src="@/assets/images/icon_ars_keymaterial_ok.png" alt="">
							<img v-else src="@/assets/images/icon_ars_keymaterial.png" alt="">
						</div>
						<div v-else-if="statusItem.value === 'protoCount'" class="tb-img">
							<img v-if="visitDetail[statusItem.value] > 0" src="@/assets/images/icon_ars_sample_ok.png" alt="">
							<img v-else src="@/assets/images/icon_ars_sample.png" alt="">
						</div>
						<div v-else-if="statusItem.value === 'onSellCount'" class="tb-img">
							<img v-if="visitDetail[statusItem.value] > 0" src="@/assets/images/icon_ars_onsell_ok.png" alt="">
							<img v-else src="@/assets/images/icon_ars_onsell.png" alt="">
						</div>
						<!-- <span class="status-name txt-ellipsis">{{ statusItem.label }}</span> -->
					</div>
				</div>
				<div class="sell-in-count" v-if="visitDetail.restockCount">
					<span>Sell-In：</span>
					<span class="pcs-txt" v-if="visitDetail.restockCount">{{ visitDetail.restockCount }}/Pcs</span>
					<van-tag round color="#f6821f" v-if="visitDetail.reserveOrder == 1">Pre</van-tag>
				</div>
			</div>
		</div>
		<img v-if="visitDetail.imgUrl" class="icon-img" :src="visitDetail.imgUrl.split(',')[0]" alt="" @click.stop="imgPreview(visitDetail.imgUrl.split(',')[0])">
		<img v-else class="icon-img" src="@/assets/images/icon_noimg.png" alt="">
	</div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
	props: {
		visitDetail: {
			type: Object,
			default: () => {}
		}
	},
	data () {
		return {
			statusList: [
        // { label: 'visit', value: 'visitCount' },
        { label: 'Sample', value: 'protoCount' },
				{ label: 'Key Material', value: 'mainMaterielCount' },
        { label: 'Material', value: 'materielCount' },
				{ label: 'On-Sell', value: 'onSellCount' },
				{ label: 'Sell-In', value: 'restockCount' }
      ]
		}
	},
	methods: {
		toVisit () {
			this.$router.push(`/visitChannelCreate?visitsId=${this.visitDetail.id}`)
		},
		imgPreview(url) {
      ImagePreview([url])
    }
	}
}
</script>

<style lang="less" scoped>
.visit-content {
	display: flex;
	align-items: center;
	padding: 10px;
	font-size: 12px;
	border-bottom: 1px solid #eeeeee;
	.visit-store-info {
		flex: 1;
		margin-right: 5px;
		.store-basic {
			display: flex;
			justify-content: space-between;
		}
		.store-data {
			display: flex;
			margin: 6px 0;
			color: #007bff;
			.project-name {
				width: 153px;
			}
			.create-time {
				width: 115px;
				text-align: right;
				font-size: 10px;
				color: #7e7e7e;
			}
		}
		.store-status {
			display: flex;
			.status-item {
				display: flex;
				align-items: center;
				font-size: 10px;
				.status-checked,
				.status-checke {
					width: 15px;
					height: 15px;
					text-align: center;
					line-height: 15px;
					border: 1px solid #007bff;
					background-color: #007bff;
					color: #fff;
					font-size: 14px;
				}
				.tb-img {
					width: 26px;
					height: 26px;
					margin-right: 10px;
					>img {
						width: 100%;
						height: 100%;
					}
				}
				.status-checke {
					border: 1px solid #007bff;
					background-color: transparent;
				}
				.status-name {
					flex: 1;
					margin-left: 5px;
				}
			}
			.sell-in-count {
				width: 123px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				font-size: 12px;
				color: red;
				.tb-img {
					width: 26px;
					height: 26px;
					margin-right: 3px;
				}
				.pcs-txt {
					margin-right: 3px;
				}
				/deep/.van-tag {
					font-size: 10px
				}
			}
		}
	}
	.icon-img {
		width: 66px;
		height: 66px;
	}
}
</style>
